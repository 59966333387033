/* eslint max-len: 0 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    "title": {
        "defaultMessage": 'FAQ',
        "id": 'app.faq.title',
    },
    "question1": {
        "defaultMessage": 'What is Breach Fighter ?',
        "id": 'app.faq.question.1',
    },
    "answer1": {
        "defaultMessage": '<span>Breach Fighter is a sandbox system developed by Stormshield. It benefit from the Stormshield Endpoint Security engine and the research of our Security team in order to detect malicious behavior in the submitted files.</span>',
        "id": 'app.faq.answer.1.html',
    },
    "question2": {
        "defaultMessage": 'How the files are analyzed ?',
        "id": 'app.faq.question.2',
    },
    "answer2": {
        "defaultMessage": '<span>The files are executed in a virtual environment and their behavior is analyzed in order to classify them according to the following list<ul><li><span class="badge badge-success rounded-0 py-1 px-2 text-uppercase">Clean</span> No malicious behavior has been detected.</li><li><span class="badge badge-warning rounded-0 py-1 px-2 text-uppercase">Suspicious</span> The analyzed file exhibits one or several suspicious behaviors. Such behaviors may also be observed in legitimate files. Before opening it or sharing it, please check that the file comes from a trusted source. For example, if you received it by mail, check that the sender is reliable.</li><li><span class="badge badge-danger rounded-0 py-1 px-2 text-uppercase">Malicious</span> The analyzed file exhibits one or several behaviors common to malwares. The file is unsafe. Do not keep, open or share.</li></ul>You can submit any kind of files but the size is limited to 100 MB.</span>',
        "id": 'app.faq.answer.2.html',
    },
    "question3": {
        "defaultMessage": 'I disagree with the classification, what can I do ?',
        "id": 'app.faq.question.3',
    },
    "answer3": {
        "defaultMessage": '<span>Near the classification there is a link “I disagree with the analysis result” you can use it to send us a message with your suggestions. Our security team will manually check the file in order to improve our detection rate and minimize our false positive.</span>',
        "id": 'app.faq.answer.3.html',
    },
    "question4": {
        "defaultMessage": 'Which file types are supported ?',
        "id": 'app.faq.question.4',
    },
    "answer4": {
        "defaultMessage": '<span>Breach Fighter is able to analyze several file types: <ul> <li>Microsoft Office documents</li> <li>PDF documents</li> <li>JAR and Java files</li> <li>Scripts (Powershell, Jscript…)</li> <li>Windows executable (32bits and 64bits)</li> <li>E-mails including attachments</li> <li>Archives (ZIP, RAR, 7zip…)</li> </ul> </span>',
        "id": 'app.faq.answer.4.html',
    },
});
