import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {Cookies} from 'react-cookie';

import CookieBanner from './CookieBanner';
import {LegalNoticeVersion} from './LegalNotice';

const CookieNameAccepted = 'accepted-cookies-version';
const CookieNameSiteUsed = 'siteused-cookies-version';
const CookieDuration = 1000 * 60 * 60 * 24 * 365; // eslint-disable-line no-magic-numbers

export const useSite = function() {
    if (useSite.userApproval) {
        useSite.userApproval.useSite();
    }
};

class NeedUserApproval extends Component {
    constructor(props) {
        super(props);
        this.cookies = new Cookies();
        this.accept = this.accept.bind(this);

        this.state = this._getState();
        useSite.userApproval = this;
    }

    _getState() {
        return {
            "accepted": (this.cookies.get(CookieNameAccepted) === LegalNoticeVersion),
            "siteUsed": (this.cookies.get(CookieNameSiteUsed) === LegalNoticeVersion),
        };
    }

    accept(){
        this.cookies.set(CookieNameAccepted, LegalNoticeVersion, {
            "expires": new Date(Date.now() + CookieDuration)
        });
        this.useSite();
    }

    useSite() {
        this.cookies.set(CookieNameSiteUsed, LegalNoticeVersion, {
            "expires": new Date(Date.now() + CookieDuration)
        });
        this.setState(this._getState());
    }

    render(){
        const content = [];

        if (this.state.siteUsed){
            content.push(<span key="content">{this.props.children}</span>);
        }

        if (!this.state.accepted) {
            content.push(<CookieBanner key="cookie" onAccept={this.accept} onClick={useSite}/>);
        }

        return (<span>{content}</span>);
    }


}

export default NeedUserApproval;
