import { defineMessages } from 'react-intl';

export default defineMessages({
    "stormshieldcom": {
        "defaultMessage": 'Stormshield',
        "id": 'app.footer.stormshieldcom',
    },
    "legal": {
        "defaultMessage": 'Legal notice',
        "id": 'app.footer.legal',
    },
});
