import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Report from "../report/Report";
import AnalysisBase from "./AnalysisBase";
import FILE_STATUS from './Status';

export default class AnalysisDetailed extends AnalysisBase {
    static propTypes = {
        "api": PropTypes.object,
        "fileHash": PropTypes.string.isRequired,
    };

    componentDidMount() {
        if (this.props.api !== undefined) {
            this.setFileReportInState(this.props.fileHash).catch(this.handleError);
        }
    }

    componentDidUpdate(prevProps) {
        // If API was not available at mount time, retry when props are updated.
        if (this.state.status === FILE_STATUS.NEW) {
            if (prevProps.api === undefined && this.props.api !== undefined) {
                this.setFileReportInState(this.props.fileHash).catch(this.handleError);
            }
        }
    }

    render() {
        return (
            <Container>
                <Report
                    status={this.state.status}
                    fileinfo={this.state.fileinfo}
                    report={this.state.report}
                    summary={this.state.summary}
                    hash={this.props.fileHash}
                    api={this.props.api}
                />
            </Container>
        );
    }
}
