import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import Dropzone from 'react-dropzone';
import {FormattedMessage} from 'react-intl';

import AnalysisShort from "../analysis/Short";
import messages from './Home.messages';
import Help from "../form/Help";
import Faq from '../Faq';

class Home extends Component {

    state = {
        "analysis": null,
        "dropzoneActive": false,
    };

    handlerNewFile(newFile){
        this.setAnalysis({"file": newFile});
    }

    handlerHash(hash){
        this.setAnalysis({"fileHash": hash});
    }

    setAnalysis(params){
        this.setState({
            "analysis": <AnalysisShort {...params} api={this.props.api} />,
        });
    }

    onDrop(newFile){
        let readNewFile = newFile;

        // On IE and Safari, newFile is an array of one file
        if (newFile instanceof Array){
            readNewFile = newFile[0];
        }
        this.setAnalysis({"file": readNewFile});
        this.setState({
            "dropzoneActive": false,
        });
    }

    onDragEnter(){
        this.setState({
            "dropzoneActive": true,
        });
    }

    onDragLeave(){
        this.setState({
            "dropzoneActive": false,
        });
    }

    render(){
        let overlay = null;

        if (this.state.dropzoneActive){
            overlay = <div className="DropZoneOverlay">
                <h1>
                    <FormattedMessage {...messages.drop} />
                </h1>
            </div>;
        }

        return <Dropzone
            onDrop={this.onDrop.bind(this)}
            onDragEnter={this.onDragEnter.bind(this)}
            onDragLeave={this.onDragLeave.bind(this)}
            multiple={false}
            disableClick
            className="fullscreen">
            {overlay}
            <div>
                <Help
                    uploadHashHandler={this.handlerHash.bind(this)}
                    uploadFileHandler={this.handlerNewFile.bind(this)}
                />
                {this.state.analysis}
                <Faq />
            </div>
        </Dropzone>;
    }
}

export default Home;

