import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {FormattedMessage} from 'react-intl';

import messages from './Footer.messages';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-table"><ul>
                    <li>
                        <a href="https://www.stormshield.com">
                            <FormattedMessage {...messages.stormshieldcom} />
                        </a>
                    </li>
                    <li>
                        <a href="/legal-notice">
                            <FormattedMessage {...messages.legal} />
                        </a>
                    </li>
                </ul></div>
            </footer>
        );
    }
}
