import { defineMessages } from 'react-intl';

export default defineMessages({
    "benign": {
        "defaultMessage": 'Clean',
        "id": 'app.report.score.benign',
    },
    "suspicious": {
        "defaultMessage": 'Suspicious',
        "id": 'app.report.score.suspicious',
    },
    "malicious": {
        "defaultMessage": 'Malicious',
        "id": 'app.report.score.malicious',
    },
    "unknown": {
        "defaultMessage": 'Unknown',
        "id": 'app.report.score.unknown',
    },
    "error": {
        "defaultMessage": 'Error',
        "id": 'app.report.score.error',
    },
});
