import { defineMessages } from 'react-intl';

export default defineMessages({
    "moreInfo": {
        "defaultMessage": 'More information about this file.',
        "id": 'app.analysis.short.moreInfo',
    },
    "wait": {
        "defaultMessage": 'Please wait.',
        "id": 'app.analysis.short.wait',
    },
    "local_processing": {
        "defaultMessage": 'Preparing file…',
        "id": 'app.analysis.short.local_process',
    },
    "submitting": {
        "defaultMessage": 'Uploading file…',
        "id": 'app.analysis.short.submitting',
    },
    "duration": {
        "defaultMessage": 'Analyzing file… Usually takes less than five minutes.',
        "id": 'app.analysis.short.duration',
    },
    "unknown": {
        "defaultMessage": 'The file has never been received by Breach Fighter. You need to submit it first.',
        "id": 'app.analysis.short.unknown',
    },
});

