import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {FormattedMessage} from 'react-intl';
import {
    Alert,
    Button,
    Popover,
    PopoverBody,
} from 'reactstrap';

import messages from './Testfile.messages';
import {useSite} from '../NeedUserApproval';

class TestfileWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "popOverOpen": false,
            "errorOccured": false,
            "tfUrl": null,
        };
        this.togglePopOverThenFetchUrl = this.togglePopOverThenFetchUrl.bind(this);
    }

    prepareDlBtn() {
        return this.props.api.getTestFileUrl()
            .then(response=>response.json())
            .then(jsonResp=>{
                this.setState({
                    "tfUrl": jsonResp.url,
                });

                return false; // no error
            })
            .catch(err=>{
                console.warn("Error", err);

                return true;
            }) // an error occured
            .then(errorOccured=>{
                if (errorOccured) {
                    this.setState({errorOccured});
                }
            });
    }

    togglePopOverThenFetchUrl() {
        let cb = this.prepareDlBtn;

        // No fetch when the popOver is closing
        if (this.state.popOverOpen) {
            cb = ()=>Promise.resolve();
        }

        this.setState({
            "popOverOpen": !this.state.popOverOpen,
            "errorOccured": false,
            "tfUrl": null,
        }, cb);

        useSite();
    }

    renderCtrContent() {
        if (this.state.errorOccured !== false) {
            return (
                <Alert color="warning" id="dlError">
                    <FormattedMessage {...messages.dlError}/>
                </Alert>
            );
        }

        if (this.state.tfUrl === null) {
            return (
                <div className="text-center pb-3">
                    <a
                        id="dl-link-disabled"
                        href="/"
                        className="btn btn-primary disabled"
                    >
                        <FormattedMessage {...messages.wait}/>
                    </a>
                </div>
            );
        }

        const plainTextUrl = `http://${window.location.host}${this.state.tfUrl}`;

        return (
            <div className="text-center pb-3">
                <a
                    id="dl-link"
                    href={plainTextUrl}
                    className="btn btn-primary"
                >
                    <FormattedMessage {...messages.downloadBtn}/>
                </a>
            </div>
        );
    }

    render() {
        return (
            <div id="tf-div">
                <Button
                    id="btn-top"
                    outline
                    color="light"
                    onClick={this.togglePopOverThenFetchUrl}
                >
                    <FormattedMessage {...messages.testFileBtn}/>
                </Button>

                <Popover
                    isOpen={this.state.popOverOpen}
                    target="btn-top"
                    toggle={this.togglePopOver}
                >
                    <PopoverBody>
                        <p><FormattedMessage {...messages.purpose}/></p>
                        {this.renderCtrContent()}
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

export default TestfileWidget;
