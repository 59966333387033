import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {PopoverBody} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import MidasRESTAPI from '../Api';
import { FormattedMessage } from 'react-intl';

import PopoverFocus from '../../helpers/PopoverFocus';
import messages from './Status.messages';

export default class MidasStatus extends Component {
    static propTypes = {
        "api": PropTypes.instanceOf(MidasRESTAPI),
    };

    state = {
        "message": "",
        "status": "up",
        "volume_status": "normal",
    }

    updateStatus(newState) {
        this.setState({
            "message": newState.result.message,
            "status": newState.result.status,
            "volume_status": newState.result.volume_status,
        });
        if (this.state.volume_status === "monthly_limit_reached") {
            // (2022/10/19) It was decided to display a "maintenance" message
            // when reaching the monthly quota for the portal.
            // In this situation, we need to override the message that is only
            // linked to the upness status of the server to avoid incoherences.
            this.setState({
                "message": <FormattedMessage { ...messages.maintenance }/>
        });
        }
    }

    getStatus() {
        this.props.api.getStatus()
            .then(result=>result.json())
            .then(result=>this.updateStatus({ result }))
            .catch(()=>this.updateStatus({ "result": {
                "message": <FormattedMessage { ...messages.unreachable }/>,
                "status": "down",
                "volume_status": "normal",
            } }));
    }

    componentDidMount() {
        if (this.props.api !== undefined){
            this.getStatus();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.api === undefined && this.props.api !== undefined) {
            this.getStatus();
        }
    }

    render() {
        if (this.state.status === "up" && this.state.volume_status !== "monthly_limit_reached") {
            return (<p></p>);
        }

        const statusClass = "text-warning pr-2";
        const statusIcon = "exclamation-triangle";

        return (
            <PopoverFocus>
                <FontAwesome size="2x" className={statusClass} name={statusIcon} />
                <PopoverBody>
                    {this.state.message}
                </PopoverBody>
            </PopoverFocus>
        );
    }
}
