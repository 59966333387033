import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import uuid from 'uuid';
import {Col, PopoverBody} from 'reactstrap';

import iconInfo from '../../img/icon-information-o.svg';
import PopoverFocus from '../../helpers/PopoverFocus';
import Feature from './Feature';

class MidasReportSection extends Component {
    render() {
        let moreInfo = "";

        if (this.props.catDetails !== undefined && this.props.catDetails !== null){
            moreInfo = <PopoverFocus>
                <img className="icon" src={iconInfo} alt="Info"/>
                <PopoverBody>
                    {this.props.catDetails}
                </PopoverBody>
            </PopoverFocus>;
        }

        return (<Col>
            <h4 className="d-inline">{this.props.catName}</h4> {moreInfo}
            <ul className="section-list">
                {this.props.features.map(item=><Feature key={uuid()} {...item} />)}
            </ul>
        </Col>);
    }
}

export default MidasReportSection;
