import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Badge} from 'reactstrap';

import messages from './Score.messages';

const SCORE_CONFIG = {
    "benign": {
        "color": "success",
        "message": <FormattedMessage {...messages.benign} />,
    },
    "slightly suspicious": {
        "color": "warning",
        "message": <FormattedMessage {...messages.suspicious} />,
    },
    "suspicious": {
        "color": "warning",
        "message": <FormattedMessage {...messages.suspicious} />,
    },
    "likely malicious": {
        "color": "danger",
        "message": <FormattedMessage {...messages.malicious} />,
    },
    "malicious": {
        "color": "danger",
        "message": <FormattedMessage {...messages.malicious} />,
    },
    "unknown": {
        "color": "info",
        "message": <FormattedMessage {...messages.unknown} />,
    },
    "error": {
        "color": "danger",
        "message": <FormattedMessage {...messages.error} />,
    },
};


class Score extends Component {
    render() {
        return <Badge className="rounded-0 py-1 px-2 text-uppercase" color={SCORE_CONFIG[this.props.label].color}>
            {SCORE_CONFIG[this.props.label].message}
        </Badge>;
    }
}

Score.propTypes = {
    "label": PropTypes.oneOf(Object.keys(SCORE_CONFIG)).isRequired,
};

export default Score;
