import { defineMessages } from 'react-intl';

export default defineMessages({
    "unreachable": {
        "defaultMessage": 'Breach Fighter is unreachable',
        "id": 'app.status.unreachable',
    },
    "maintenance": {
        "defaultMessage": 'Breach Fighter is in maintenance',
        "id": 'app.status.maintenance',
    }
});
