import { defineMessages } from 'react-intl';

export default defineMessages({
    "error": {
        "defaultMessage": 'Error',
        "id": 'app.analysis.error',
    },
    "inProgress": {
        "defaultMessage": 'Analysis in progress…',
        "id": 'app.analysis.inProgress',
    },
    "unknown": {
        "defaultMessage": 'File is unknown',
        "id": 'app.analysis.unknown',
    },
});
