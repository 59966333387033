import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FILE_STATUS from "./Status";
import { MidasErrorComponent, MidasError } from '../Errors';

const SCORE_REQ_PERIOD = 5000;

export default class AnalysisBase extends Component {
    static propTypes = {
        "api": PropTypes.any,
    };

    state = {
        "result": {"details": ""},
        "status": FILE_STATUS.NEW,
        "summary": undefined,
        "fileinfo": undefined,
        "fileName": undefined,
        "fileHash": undefined,
    }

    clearTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    sendHashRequest(fileHash) {
        return this.getHashResult(fileHash);
    }

    getHashResult(fileHash) {
        if (this.props.api === undefined) {
            return undefined;
        }

        return this.props.api.hashResult(fileHash).then(response => response.json());
    }

    setFileReportInState(fileHash) {
        return new Promise(resolve => {
            const inner = () => {
                this.getHashReport(fileHash)
                    .then(report => {
                        this.setState({
                            "fileinfo": report.fileinfo,
                            "report": report.report,
                            "summary": report.summary,
                        });
                        if ("fileinfo" in report && "score" in report.fileinfo) {
                            this.setState({ "status": FILE_STATUS.FINISH });
                            resolve();
                        } else {
                            this.setState({ "status": FILE_STATUS.ANALYSIS_IN_PROGRESS });
                            this.timeout = setTimeout(inner, SCORE_REQ_PERIOD);
                        }
                    })
                    .catch(() => {
                        this.setState({ "status": FILE_STATUS.FILE_UNKNOWN });
                        resolve();
                    });
            };

            inner();
        });
    }

    getHashReport(fileHash) {
        if (this.props.api === undefined) {
            return undefined;
        }

        return this.props.api.hashReport(fileHash).then(response => response.json());
    }

    handleError(error) {
        if (error instanceof MidasError) {
            this.setState({
                "status": FILE_STATUS.ERROR,
                "fileinfo": {"details": <MidasErrorComponent error={error} />},
            });
        } else {
            console.warn(error);
        }
    }
}
