import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import MidasRESTAPI from './Api';
import { MidasErrorComponent } from './Errors';
import messages from './Misclassification.messages';
import { useSite } from './NeedUserApproval';

export default class Misclassification extends Component {
    static propTypes = {
        "api": PropTypes.instanceOf(MidasRESTAPI),
        "hash": PropTypes.string,
        "className": PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            "modal": false,
            "message": '',
            "resultModal": false,
            "resultTitle": "",
            "resultText": ""
        };
    }

    toggle() {
        this.setState({
            "modal": !this.state.modal,
            "message": '',
        });
        useSite();
    }

    hideResult() {
        this.setState({
            "resultModal": false,
        });
    }

    showResult(title, text) {
        this.toggle();
        this.setState({
            "resultModal": true,
            "resultTitle": title,
            "resultText": text,
        });
    }

    send() {
        if (this.props.api === undefined) {
            return undefined;
        }

        return this.props.api.sendMisclassification(this.props.hash, this.state.message)
            .then(() => {
                this.showResult(<FormattedMessage {...messages.sent} />,
                    <FormattedMessage {...messages.sentMessage} />
                );
            })
            .catch(result => {
                this.showResult(<FormattedMessage {...messages.error} />,
                    <MidasErrorComponent error={result} />
                );
            });
    }

    handleMessageChange(event) {
        this.setState({"message": event.target.value});
    }

    render() {
        let resultModalBody = "";

        if (this.state.resultText !== "") {
            resultModalBody = <ModalBody>{this.state.resultText}</ModalBody>;
        }

        return (
            <span>
                <Button color="link" className="misclassLink" id="misclassButton" onClick={this.toggle.bind(this)}>
                    <FormattedMessage {...messages.reportLink} />
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    id="modalQuery"
                    toggle={this.toggle.bind(this)}
                    className={this.props.className}
                >
                    <ModalHeader toggle={this.toggle.bind(this)}>
                        <FormattedMessage {...messages.reporterror} />
                    </ModalHeader>
                    <ModalBody>
                        <Label for="reportMessage">
                            <FormattedMessage {...messages.message} />
                        </Label>
                        <Input
                            type="textarea"
                            value={this.state.message}
                            onChange={this.handleMessageChange.bind(this)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" id="misclassSendButton" onClick={this.send.bind(this)}>
                            <FormattedMessage {...messages.send} />
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggle.bind(this)}>
                            <FormattedMessage {...messages.cancel} />
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.resultModal}
                    id="modalResult"
                    toggle={this.hideResult.bind(this)}
                    className={this.props.className}
                >
                    <ModalHeader>
                        {this.state.resultTitle}
                    </ModalHeader>
                    {resultModalBody}
                    <ModalFooter>
                        <Button color="primary" onClick={this.hideResult.bind(this)}>
                            <FormattedMessage {...messages.ok} />
                        </Button>{' '}
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
