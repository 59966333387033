import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {Container, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

import messages from './Marketing.messages';
import Ad from './Ad';

import imgAdSBF from './ad_sbf.jpg';
import imgAdSES from './ad_ses.jpg';
import imgAdSDS from './ad_sds.jpg';

class Marketing extends Component {
    constructor(props) {
        super(props);
        this.ads = [];

        this.ads.push(<Ad
            key="ad-sbf"
            img={imgAdSBF}
            text={<FormattedMessage {...messages.textSBF} />}
            linkText={<FormattedMessage {...messages.linkSBF} />}
            link="https://www.stormshield.com/fr/produits/breach-fighter/"
        />);
        this.ads.push(<Ad
            key="ad-ses"
            img={imgAdSES}
            text={<FormattedMessage {...messages.textSES} />}
            linkText={<FormattedMessage {...messages.linkSES} />}
            link="https://www.stormshield.com/fr/produits-et-services/produits/protection-des-postes-et-serveurs/"
        />);
        this.ads.push(<Ad
            key="ad-sds"
            img={imgAdSDS}
            text={<FormattedMessage {...messages.textSDS} />}
            linkText={<FormattedMessage {...messages.linkSDS} />}
            link="https://www.stormshield.com/fr/produits-et-services/produits/protection-des-donnees/"
        />);
    }

    render(){
        return <Container>
            <h2 className="mb-3 mt-5">
                <FormattedMessage {...messages.title} />
            </h2>
            <Row>
                {this.ads}
            </Row>
        </Container>;
    }
}

export default Marketing;
