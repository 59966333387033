import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {FormattedMessage} from 'react-intl';
import {Row, Col, Badge, PopoverBody} from 'reactstrap';
import DOMPurify from 'dompurify'

import iconInfo from '../../img/icon-information-o.svg';
import PopoverFocus from '../../helpers/PopoverFocus';
import messages from './Feature.messages';

const labels = {
    "information": (
        <Badge className="rounded-0 py-1 px-2 text-uppercase" color="info">
            <FormattedMessage {...messages.information} />
        </Badge>),
    "malicious": (
        <Badge className="rounded-0 py-1 px-2 text-uppercase" color="danger">
            <FormattedMessage {...messages.malicious} />
        </Badge>),
    "suspicious": (
        <Badge className="rounded-0 py-1 px-2 text-uppercase" color="warning">
            <FormattedMessage {...messages.suspicious} />
        </Badge>),
};

class Feature extends Component {
    render() {
        if (this.props.info === undefined) {
            return "";
        }

        let label = "";
        let moreInfo = "";

        if (this.props.details !== undefined){
            moreInfo = <PopoverFocus>
                <img className="icon" src={iconInfo} alt="Info"/>
                <PopoverBody>
                    {this.props.details}
                </PopoverBody>
            </PopoverFocus>;
        }

        if (this.props.level !== undefined){
            label = labels[this.props.level];
        }

        return (<li>
            <Row>
                <Col xs={12} sm={3} lg={2} className="text-sm-right feature-label">
                    {label}
                </Col>
                <Col xs={12} sm={9} lg={10}>
                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.info)}}/>
                    {moreInfo}
                </Col>
            </Row>
        </li>);
    }
}

export default Feature;
