import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react'; // eslint-disable-line no-unused-vars

import messages from './Errors.messages';

class MidasError {
    constructor(errorId, details) {
        this.errorId = errorId;
        this.details = details;
    }
}

class MidasErrorComponent extends Component {
    render() {
        if (this.props.error.details) {
            console.warn(this.props.error.details);
        }

        return <FormattedMessage {...messages[this.props.error.errorId]} />;
    }
}

MidasErrorComponent.propTypes = {
    "error": PropTypes.object.isRequired,
};

export {MidasError, MidasErrorComponent};
