import { defineMessages } from 'react-intl';

export default defineMessages({
    "Byte": {
        "defaultMessage": 'Byte',
        "id": 'app.report.fileinfo.size.byte',
    },
    "Bytes": {
        "defaultMessage": 'Bytes',
        "id": 'app.report.fileinfo.size.bytes',
    },
    "GiB": {
        "defaultMessage": 'GiB',
        "id": 'app.report.fileinfo.size.gib',
    },
    "KiB": {
        "defaultMessage": 'KiB',
        "id": 'app.report.fileinfo.size.kib',
    },
    "MiB": {
        "defaultMessage": 'MiB',
        "id": 'app.report.fileinfo.size.mib',
    },
    "fileInfo": {
        "defaultMessage": 'File information',
        "id": 'app.report.fileInfo',
    },
    "fileSize": {
        "defaultMessage": 'File Size',
        "id": 'app.report.fileinfo.fileSize',
    },
    "firstSeen": {
        "defaultMessage": 'First Seen',
        "id": 'app.report.fileinfo.firstSeen',
    },
    "md5": {
        "defaultMessage": 'MD5',
        "id": 'app.report.fileinfo.md5',
    },
    "mimeType": {
        "defaultMessage": 'Mime Type',
        "id": 'app.report.fileinfo.mimeType',
    },
    "sha1": {
        "defaultMessage": 'SHA1',
        "id": 'app.report.fileinfo.sha1',
    },
    "sha256": {
        "defaultMessage": 'SHA256',
        "id": 'app.report.fileinfo.sha256',
    },
});
