import PropTypes from 'prop-types';
import React, { Component} from 'react'; // eslint-disable-line no-unused-vars
import {Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import uuid from 'uuid';

class PopoverFocus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "tooltipOpen": false,
            "idTooltip": `tooltip-${uuid()}`,
        };
    }

    open() {
        this.setState({
            "tooltipOpen": true,
        });
    }

    close() {
        this.setState({
            "tooltipOpen": false,
        });
    }

    render() {
        const body = [];
        let popBody = null;
        let popHeader = null;

        this.props.children.forEach(child=>{
            if (child.type === PopoverBody){
                popBody = child;
            } else if (child.type === PopoverHeader){
                popHeader = child;
            } else {
                body.push(child);
            }
        });

        return (
            <span
                className="popover_focus"
                id={this.state.idTooltip}
                onMouseEnter={this.open.bind(this)}
                onMouseLeave={this.close.bind(this)}
            >
                {body}
                <Popover placement="auto" isOpen={this.state.tooltipOpen} target={this.state.idTooltip}>
                    {popHeader}
                    {popBody}
                </Popover>
            </span>
        );
    }
}

PopoverFocus.propTypes = {
    "children": PropTypes.array.isRequired,
};

export default PopoverFocus;
