import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {FormattedMessage} from 'react-intl';

import messages from './WrongURL.messages';

class WrongURL extends Component {
    render(){
        return <h2>
            <FormattedMessage {...messages.wrongURL} />
        </h2>;
    }
}

export default WrongURL;
