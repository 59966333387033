import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { Row, Col, Table } from 'reactstrap';
import filesize from 'file-size';
import { FormattedMessage, FormattedRelativeTime, FormattedDate, FormattedTime } from 'react-intl';

import Hash from './Hash';
import messages from './FileInfo.messages';

class MidasReportFileInfo extends Component {

    generateProp(item) {
        const [key, label, transform] = item;
        let value = this.props.fileinfo[key];

        if (value === undefined) {
            return null;
        }

        if (transform !== undefined){
            value = transform(value);
        }

        return <tr id={key} key={key}><th scope="row">{label}</th><td>{value}</td></tr>;
    }

    transformHash(val) {
        return <Hash value={val}/>;
    }

    transformFileSize(val) {
        const data = filesize(val).calculate();


        /*
        * Special treatment to erase the decimal numbers
        * in the case of a size expressed in Bytes.
        * See SAAS-1100 for details.
        */
        let fileSize = data.fixed;

        if (data.suffix === "Bytes" ) {
            const stringLength = fileSize.length;
            /* eslint-disable no-magic-numbers */
            fileSize = fileSize.slice(0, stringLength - 3);
        }

        return <span>
            <span id="file_size_value">{fileSize}</span>
            <FormattedMessage {...messages[data.suffix]} />
        </span>;
    }

    transformDate(val) {
        // Safari and IE can't parse date from String. So we do the job
        const dateSplited = val.split(" ");
        const [year, month, day] = dateSplited[0].split("-");
        const [hours, minutes, seconds] = dateSplited[1].split(":");

        // Analyses time is always UTC time.
        // It we display it without taking into account the user time difference,
        // it can cause confusion. See SAAS-1099.
        let timeDiff = new Date().getTimezoneOffset();

        // Month start is 0 to 11 (january to december) so we remove 1
        /* eslint-disable no-magic-numbers */
        const date = new Date(year, (month - 1), day, hours, minutes - timeDiff, seconds);
        const secondDelta = ((date - Date.now()) / 1000).toFixed();
        /* eslint-enable no-magic-numbers */

        return <span>
            <FormattedRelativeTime
                value={secondDelta}
                unit="second"
                numeric="auto"
                updateIntervalInSeconds={60} />
            &nbsp;
            <span className="font-italic">
                (<FormattedDate value={date} /> <FormattedTime value={date} />)
            </span>
        </span>;
    }

    render() {
        /* eslint-disable react/jsx-key */
        const legends = [
            ["first_seen", <FormattedMessage {...messages.firstSeen} />, this.transformDate],
            ["mime", <FormattedMessage {...messages.mimeType} />],
            ["size", <FormattedMessage {...messages.fileSize} />, this.transformFileSize],
            ["md5", <FormattedMessage {...messages.md5} />, this.transformHash],
            ["sha1", <FormattedMessage {...messages.sha1} />, this.transformHash],
            ["sha256", <FormattedMessage {...messages.sha256} />, this.transformHash]];
        /* eslint-enable react/jsx-key */
        const listOfProps = [];

        if (this.props.fileinfo !== undefined) {
            for (let i = 0; i < legends.length; i++) {
                listOfProps.push(this.generateProp(legends[i]));
            }
        }

        if (listOfProps.length > 0) {
            return (<span>
                <Row>
                    <Col>
                        <h2 className="mb-3 mt-5"><FormattedMessage {...messages.fileInfo} /></h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} lg={6}>
                        <Table className="file-info" striped bordered>
                            <tbody>
                                {listOfProps}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </span>);
        }

        return <span></span>;
    }
}

export default MidasReportFileInfo;
