/* eslint max-len: 0 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    "http413": {
        "defaultMessage": 'The file is too big to be analyzed by the Breach Fighter online service. You can only send files smaller than 100M.',
        "id": 'errors.http413',
    },
    "unknownError": {
        "defaultMessage": 'An internal error occurred. Please try again later.',
        "id": 'errors.unknownError',
    },
});
