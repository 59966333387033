/*
    Mostly based on https://www.npmjs.com/package/react-recaptcha
    with PR #207 applied to support invisible reCAPTCHA:
    https://github.com/appleboy/react-recaptcha/pull/207/files
    and script retrieval from:
    https://github.com/szchenghuang/react-google-invisible-recaptcha/blob/master/src/index.js
*/

import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

class Recaptcha extends Component {
    constructor(props) {
        super(props);
        this.pendingChallenges = [];
        this.fetch = null;
        this.widget = null;
    }

    fetchReCaptcha() {
        if (this.fetch !== null) {
            return this.fetch;
        }

        this.fetch = new Promise(resolve=>{
            const script = document.createElement('script');

            window.reCaptchaLoaded = ()=>{
                resolve();
            };
            script.id = 'recaptcha';
            script.src = `https://www.google.com/recaptcha/api.js` +
            `?hl=${this.props.locale}&onload=reCaptchaLoaded&render=explicit`;
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.onerror = error=>{
                throw error;
            };
            document.body.appendChild(script);
        });

        return this.fetch;
    }

    getWidget() {
        if (this.widget !== null) {
            return this.widget;
        }

        this.widget = new Promise(resolve=>{
            const realWidget = window.grecaptcha.render(this.props.elementID, {
                "sitekey": this.props.sitekey,
                "callback": ()=>{
                    const resolvePendingChallenge = this.pendingChallenges.shift();

                    resolvePendingChallenge(window.grecaptcha.getResponse(realWidget));
                    window.grecaptcha.reset(realWidget);
                },
                "theme": this.props.theme,
                "type": this.props.type,
                "size": this.props.size,
                "tabindex": this.props.tabindex,
                "hl": this.props.hl,
                "badge": this.props.badge,
            });

            resolve(realWidget);
        });

        return this.widget;
    }

    getCaptchaChallengeResponse() {
        return new Promise(resolve=>{
            this.pendingChallenges.push(resolve);
            this.fetchReCaptcha()
                .then(()=>this.getWidget())
                .then(widget=>{
                    window.grecaptcha.execute(widget);
                });
        });
    }

    render() {
        return (
            <div id={this.props.elementID}
                data-onloadcallbackname={this.props.onloadCallbackName}
                data-verifycallbackname={this.props.verifyCallbackName}
            />
        );
    }
}

Recaptcha.propTypes = {
    "badge": PropTypes.string,
    "elementID": PropTypes.string.isRequired,
    "locale": PropTypes.string.isRequired,
    "sitekey": PropTypes.string.isRequired,
    "size": PropTypes.string,
    "tabindex": PropTypes.string,
    "theme": PropTypes.string,
    "type": PropTypes.string,
};

Recaptcha.defaultProps = {
    "badge": 'bottomright',
    "elementID": 'g-recaptcha',
    "locale": 'en',
    "size": 'normal',
    "tabindex": '0',
    "theme": 'light',
    "type": 'image',
};

export default Recaptcha;
