import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';

import App from './app/App';
import LanguageProvider from './LanguageProvider';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

ReactDOM.render(
    <LanguageProvider>
        <App />
    </LanguageProvider>,
    document.getElementById('root'));
