/* eslint max-len: 0 */
import { defineMessages } from 'react-intl';

const defaultMessage = `
<h1>Terms of Use and Legal Notice</h1>
<p>Before using STORMSHIELD Breach Fighter Portal, you must carefully read and agree with all the terms and conditions hereafter. These terms and conditions can, if necessary, be modified.</p>
<h2>Terms of service</h2>
<p>Each person using Breach Fighter Portal (<a href="https://breachfighter.stormshieldcs.eu">breachfighter.stormshieldcs.eu</a>) undertakes to respect terms of service.</p>
<p>Each person is responsible for data which he spreads, uses and/or transfers. Each user has to respect French law, notably regulations related to illicit contents or the protection of rights of third parties, especially Intellectual Property Rights.</p>
<p>Intellectual Property means trademarks, registered designs, patents and copyright (notably information, photographs, audio and video clips, picture images, graphics, links, site architecture, software).</p>
<p>STORMSHIELD is not responsible for the use of the data, for the spread of the contents or for the services from another person and who, notably, would not respect the regulations in to force.</p>
<p>Besides, STORMSHIELD can revise the Breach Fighter Portal and make changes or suppression at any time without prior communication.</p>
<p>Into the limit of the law, STORMSHIELD and its by-contractors decline any responsibility for damages resulting directly or indirectly from the use of the portal including, and without being restrictive, direct and/or indirect damages. </p>
<h2>Intellectual property rights</h2>
<p>Data presented in Breach Fighter Portal (<a href="https://breachfighter.stormshieldcs.eu">breachfighter.stormshieldcs.eu</a>) including, notably, trademarks, registered designs, logos and products, is protected by STORMSHIELD intellectual property.</p>
<p>Any distribution, reproduction or representation, even partial of these data, in other purposes than a privative use is forbidden and could engage civil and penal liability of the person which would not respect this policy.</p>
<p>In particular, the use of the Breach Fighter Portal to create a service designed to detect malware programs is forbidden.</p>
<h2>Data collected </h2>
<p>For each file uploaded, we collect:</p>
<ul><li>The file itself,</li>
<li>And your IP address.</li></ul>
<p>Those data are stored on dedicated physical servers administered by Stormshield and hosted in OVH datacenters in France.</p>
<p>By submitting a file, you confirm and agree that this file does not contain personal or confidential information. In case of doubt about the file&#39;s sensitivity, you should not submit it.</p>
<p>Information collected benefits of the protection law &#34;Informatique et Libert&#xE9;s&#34; n&#xb0;78-17 from the 06th of January 1978 and of the Directive 95/46/CE from the 24th of October 1995. Users also benefit, following article 34, of a right of access, modification, opposition of communication and right to suppression by simple request to: <a href="mailto:breachfighter@stormshield.eu">breachfighter@stormshield.eu</a>.</p>
<h2>Processing operations</h2>
<p>For each file submitted, the following operations are performed:</p>
<ul><li>Comparison with known malicious files.</li>
<li>Behavior analysis: the file is open in a VM and its behavior is monitored.</li>
<li>Advanced analysis by our experts in case of suspicion of new attack method.</li></ul>
<h2>Retention period</h2>
<p>Files are retained for one month. An exception is made for suspicious or malicious files which are kept for at most 6 months. During this period, the file can be subject to:</p>
<ul><li>Another analysis in order to validate a new version of our detection module or to go through  a new detection method. </li>
<li>A deeper manual analysis in case of detection failure or disagreement with the analysis result. </li></ul>
<h2>Confidentiality</h2>
<p>We take the commitment to respect the confidentiality of information collected. We refrain ourselves from any resale of files, as mailing addresses, e-mail addresses, telephone numbers, fax numbers or about any other information. </p>
<h2>Protection of personal data</h2>
<p>We implement commercially reasonable security procedures to maintain the integrity and security of your personal information. In particular:</p>
<ul><li>We host and process your data obligatorily in the European Union.</li>
<li>We encrypt our data transfers using SSL/TLS.</li>
<li>We conduct internal audits on the collection, storage and processing of data, including physical security measures, to prevent unauthorized access to our systems.</li>
<li>Access to personal data is strictly reserved for employees and partners who need to access it in order to process it on our behalf. These persons are subject to strict confidentiality obligations and may be subject to disciplinary sanctions up to and including termination of employment in the event of a breach of these obligations.</li></ul>
<h2>Cookies</h2>
<p>Breach Fighter Portal (<a href="https://breachfighter.stormshieldcs.eu">breachfighter.stormshieldcs.eu</a>) uses &#34;cookies&#34; to check if the submitter is not a robot (cookies are pieces of data stored on your computer&#39;s hard drive or browser). Caution! If you disable those cookies, the service cannot be provided.</p>
<h2>Technical Support</h2>
<p>Technical support is not provided.</p>
<h2>Information on Company</h2>
<b>STORMSHIELD SAS</b>
<p>Stormshield, a French simplified stock corporation capitalized at: &#x20ac;2,793,881.60<br />
Trade and Companies Register Number: RCS 428 173 975<br />
Intracommunity VAT Number: FR19 428 173 975<br />
<p>Address of Registered Office<br />
Immeuble Axium, B&#xE2;t. D &#x2013; 2&#xE8;me &#xE9;tage<br />
22, rue du Gouverneur G&#xE9;n&#xE9;ral Ebou&#xE9;<br />
92130 Issy-les-Moulineaux<br />
FRANCE</p>
<p><b>Hosting Service Provider: OVH</b><br />
2, rue Kellermann 59100 ROUBAIX<br />
+33 9 72 10 10 07</p>
<p><b>Contact</b><br />
For further information on this portal, you can contact us at: <a href="mailto:breachfighter@stormshield.eu">breachfighter@stormshield.eu</a></p>
`;

export default defineMessages({
    "legal_contents": {
        defaultMessage,
        "id": 'app.legal.legal_contents.html',
    },
});
