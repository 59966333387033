import React, {Component} from 'react'; // eslint-disable-line no-unused-vars
import {Container} from 'reactstrap';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

import messages from './Faq.messages';
import FaqItem from './FaqItem';

class Faq extends Component {

    constructor(props) {
        super(props);
        this.faqItems = [];

        for (const prop in messages){
            if (prop.indexOf('question') === 0){
                const index = prop.replace('question', '');

                this.faqItems.push(<FaqItem key={`faqItem-${index}`} title={<FormattedMessage {...messages[prop]} />}>
                    <FormattedHTMLMessage {...messages[`answer${index}`]} />
                </FaqItem>);
            }
        }
    }

    render() {
        return <Container>
            <h2 className="mb-3 mt-5">
                <FormattedMessage {...messages.title} />
            </h2>
            {this.faqItems}
        </Container>;
    }
}

export default Faq;
