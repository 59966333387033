import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { Container } from 'reactstrap';
import {FormattedHTMLMessage} from 'react-intl';
import messages from './LegalNotice.messages';

const LegalNoticeVersion = 'V2';

class LegalNotice extends Component {
    render() {
        return (<Container>
            <FormattedHTMLMessage {...messages.legal_contents} />
        </Container>);
    }
}

export default LegalNotice;
export {LegalNoticeVersion};
