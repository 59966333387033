import CopyToClipBoard from 'react-copy-to-clipboard';
import {FormattedMessage} from 'react-intl';
import {Popover, PopoverBody} from 'reactstrap';
import PropTypes from 'prop-types';
import React, { Component } from 'react'; // eslint-disable-line no-unused-vars

import iconCopy from '../../img/icon-copy.svg';
import messages from './Hash.messages';

const HASH_LENGTH = 25;

class Hash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "tooltipOpen": false,
            "idTooltip": `tooltip-${this.props.value}`,
            "message": <FormattedMessage {...messages.copy} />,
        };
    }

    open() {
        this.setState({
            "tooltipOpen": true,
        });
    }

    copy() {
        this.setState({
            "message": <FormattedMessage {...messages.copied} />,
        });
        this.open();
    }

    close() {
        this.setState({
            "tooltipOpen": false,
            "message": <FormattedMessage {...messages.copy} />,
        });
    }

    render() {
        return (
            <div className="hash text-truncate">
                <CopyToClipBoard
                    onMouseEnter={this.open.bind(this)}
                    onMouseLeave={this.close.bind(this)}
                    onCopy={this.copy.bind(this)}
                    text={this.props.value}
                >
                    <img className="icon" id={this.state.idTooltip} src={iconCopy} alt="Copy"/>
                </CopyToClipBoard>
                <Popover placement="auto" isOpen={this.state.tooltipOpen} target={this.state.idTooltip}>
                    <PopoverBody>
                        {this.state.message}
                    </PopoverBody>
                </Popover>
                {this.props.value.substring(0, HASH_LENGTH)}&hellip;
            </div>
        );
    }
}

Hash.propTypes = {
    "value": PropTypes.string.isRequired,
};

export default Hash;
