import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {Button} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

import messages from './CookieBanner.messages';

class CookieBanner extends Component {
    render(){
        return (
            <div className="fixed-bottom py-2 mb-0 alert alert-warning rounded-0">
                <div className="container d-flex align-items-center">
                    <div className="align-self-center mr-auto">
                        <FormattedMessage {...messages.warning} />
                        <a className="pl-1" href="/legal-notice"><FormattedMessage {...messages.moreInfo} /></a>
                    </div>
                    <Button color="success" className="flex-column-reverse" onClick={this.props.onAccept}>
                        <FormattedMessage {...messages.ok}/>
                    </Button>
                </div>
            </div>
        );
    }
}

export default CookieBanner;
