import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {PopoverBody} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import {FormattedMessage} from 'react-intl';

import PopoverFocus from '../../helpers/PopoverFocus';
import messages from './Status.messages';

const FILE_STATUS = {
    "NEW": "",
    "LOCAL_PROCESSING": "hashInProgress",
    "SUBMITTING": "submitInProgress",
    "ANALYSIS_IN_PROGRESS": (
        <PopoverFocus className="analysis-in-progress">
            <FontAwesome spin className="text-info" name="refresh" size="3x" />
            <PopoverBody>
                <FormattedMessage {...messages.inProgress} />
            </PopoverBody>
        </PopoverFocus>
    ),
    "FINISH": "finish",
    "FILE_UNKNOWN": (
        <PopoverFocus className="analysis-file-unknown">
            <FontAwesome className="text-info" name="question" size="3x" />
            <PopoverBody>
                <FormattedMessage {...messages.unknown} />
            </PopoverBody>
        </PopoverFocus>
    ),
    "ERROR": (
        <PopoverFocus className="analysis-error">
            <FontAwesome className="text-info" name="times" size="3x" />
            <PopoverBody>
                <FormattedMessage {...messages.error} />
            </PopoverBody>
        </PopoverFocus>
    ),
};

export default FILE_STATUS;
