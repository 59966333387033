/* eslint max-len: 0 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    "title": {
        "defaultMessage": 'Face aux malwares, optez pour la bonne protection',
        "id": 'app.marketing.title',
    },
    "textSBF": {
        "defaultMessage": 'Protégez vos réseaux avec la capacité de détonation de Breach Fighter directement intégrée dans des pare-feux UTM nouvelle génération',
        "id": 'app.marketing.sbf.text',
    },
    "linkSBF": {
        "defaultMessage": 'Découvrir notre solution Breach Fighter',
        "id": 'app.marketing.sbf.link',
    },
    "textSES": {
        "defaultMessage": 'Sécurisez vos environnements sensibles avec le même moteur d’analyse comportementale que Breach Fighter',
        "id": 'app.marketing.ses.text',
    },
    "linkSES": {
        "defaultMessage": 'Découvrir notre solution Stormshield Endpoint Security',
        "id": 'app.marketing.ses.link',
    },
    "textSDS": {
        "defaultMessage": 'Prévenez le vol de vos données avec un chiffrement efficace de bout en bout',
        "id": 'app.marketing.sds.text',
    },
    "linkSDS": {
        "defaultMessage": 'Découvrir notre gamme Stormshield Data Security',
        "id": 'app.marketing.sds.link',
    },

});

