import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { Container, Jumbotron } from 'reactstrap';
import {FormattedMessage} from 'react-intl';

import Form from "./Form";
import logoBreachFighter from '../../img/stormshield_breach_fighter_logo.svg';
import messages from './Help.messages';

class Help extends Component {
    render() {
        return (
            <Jumbotron>
                <Container>
                    <img id="logo-bf" src={logoBreachFighter} alt="Breach Fighter"/>
                    <h5><FormattedMessage {...messages.general} /></h5>
                    <Form {...this.props} />
                </Container>
            </Jumbotron>
        );
    }
}

export default Help;
