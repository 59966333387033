import { defineMessages } from 'react-intl';

export default defineMessages({
    "warning": {
        "defaultMessage": 'We only use technical cookies necessary for the proper functioning of our portal. These cookies cannot be deactivated.',
        "id": 'app.cookie.warning',
    },
    "moreInfo": {
        "defaultMessage": 'More details',
        "id": 'app.cookie.moreInfo',
    },
    "ok": {
        "defaultMessage": 'Ok, I got it',
        "id": 'app.cookie.ok',
    },
});
