import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import FontAwesome from 'react-fontawesome';
import { Collapse, Button, CardBody} from 'reactstrap';
import {useSite} from './NeedUserApproval';

class FaqItem extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            'open': false,
        };
    }

    toggle() {
        this.setState({ 'open': !this.state.open });
        useSite();
    }

    render() {
        let iconClass = "text-dark rotate";

        if (this.state.open) {
            iconClass += " down";
        }

        return <div className="card">
            <div className="border-bottom-0 p-0 card-header" id="headingOne">
                <Button className="d-block w-100 text-left" color="link" onClick={this.toggle}>
                    <h6 className="mb-0">
                        <FontAwesome className={iconClass} name="chevron-right"/> {this.props.title}
                    </h6>
                </Button>
            </div>
            <Collapse isOpen={this.state.open}>
                <CardBody>
                    {this.props.children}
                </CardBody>
            </Collapse>
        </div>;
    }
}

export default FaqItem;
