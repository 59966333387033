import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {IntlProvider} from 'react-intl';

import LanguageSupported from './LanguageSupported';

class LanguageProvider extends Component {
    constructor(props){
        super(props);

        let locales = [];

        if (navigator.languages) { // Modern browser
            if (Intl.getCanonicalLocales){ // Very Modern browser
                locales = Intl.getCanonicalLocales(navigator.languages);
            } else {
                locales = [...navigator.languages];
            }
        } else if (navigator.language){
            locales.push(navigator.language);
        }

        // English as fallback
        locales.push("en");
        // If lang is "en-US", just keep "en"
        locales = locales.map(locale=>locale.split("-")[0].toLowerCase());

        let localeIdx = 0;

        while (!(locales[localeIdx] in LanguageSupported)) {
            localeIdx++;
        }

        this.state = {
            "locale": locales[localeIdx],
        };
    }

    render() {
        return (
            <IntlProvider locale={this.state.locale} messages={LanguageSupported[this.state.locale].localData}>
                {this.props.children}
            </IntlProvider>
        );
    }

}

export default LanguageProvider;
