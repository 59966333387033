import { defineMessages } from 'react-intl';

export default defineMessages({
    "hashPlaceHolder": {
        "defaultMessage": "enter the SHA256 of the file",
        "id": "app.form.hashPlaceHolder",
    },
    "or": {
        "defaultMessage": "or",
        "id": "app.form.or",
    },
    "selectFile": {
        "defaultMessage": "Select a file",
        "id": "app.form.selectFile",
    },
});
