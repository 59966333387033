import { defineMessages } from 'react-intl';

export default defineMessages({
    "reportLink": {
        "defaultMessage": 'I disagree with the analysis result',
        "id": 'app.misclassification.reportLink',
    },
    "reporterror": {
        "defaultMessage": 'Report a classification error on this file',
        "id": 'app.misclassification.reporterror',
    },
    "message": {
        "defaultMessage": 'Enter your message',
        "id": 'app.misclassification.message',
    },
    "send": {
        "defaultMessage": 'Send report',
        "id": 'app.misclassification.send',
    },
    "cancel": {
        "defaultMessage": 'Cancel',
        "id": 'app.misclassification.cancel',
    },
    "error": {
        "defaultMessage": 'Error while reporting',
        "id": 'app.misclassification.error',
    },
    "sent": {
        "defaultMessage": 'Report sent',
        "id": 'app.misclassification.sent',
    },
    "sentMessage": {
        "defaultMessage": 'Thank you for your feedback.',
        "id": 'app.misclassification.sentMessage',
    },
    "ok": {
        "defaultMessage": 'OK',
        "id": 'app.misclassification.ok',
    },
});
