import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { Form, Button } from 'reactstrap';
import FileReaderInput from 'react-file-reader-input';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import messages from './Form.messages';
import { useSite } from '../NeedUserApproval';

const sha265Size = 64;

class MidasForm extends Component {
    static propTypes = {
        "intl": PropTypes.any.isRequired, // IntlShape.
        "uploadFileHandler": PropTypes.func,
        "uploadHashHandler": PropTypes.func,
    };

    handleSubmit(event, results) {
        results.forEach(result => {
            const [e, file] = result;

            if (e.target.readyState !== FileReader.DONE) {
                return;
            }
            file.getData = new Promise(resolve => resolve(e.target.result));
            this.props.uploadFileHandler(file);
        });
    }

    handleHash(event) {
        if (event.target.value.length === sha265Size) {
            this.props.uploadHashHandler(event.target.value);
            event.target.value = "";
        }
        useSite();
    }

    formSubmit(event) {
        // see https://github.com/ReactTraining/react-router/issues/1933
        event.preventDefault();
        useSite();
    }

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <Form id="submit-form" onSubmit={this.formSubmit.bind(this)}>
                <FileReaderInput onChange={this.handleSubmit.bind(this)} as="buffer">
                    <Button className="align-baseline">
                        <FormattedMessage {...messages.selectFile} />
                    </Button>
                </FileReaderInput>

                &nbsp;<FormattedMessage {...messages.or} />&nbsp;

                <input
                    type="text"
                    title={formatMessage(messages.hashPlaceHolder)}
                    pattern="^[0-9a-fA-F]{64}$"
                    onChange={this.handleHash.bind(this)}
                    placeholder={formatMessage(messages.hashPlaceHolder)}
                    maxLength={sha265Size}
                    size={sha265Size} />
            </Form>
        );
    }
}

export default injectIntl(MidasForm);
