import { defineMessages } from 'react-intl';

export default defineMessages({
    "information": {
        "defaultMessage": 'Information',
        "id": 'app.report.feature.information',
    },
    "malicious": {
        "defaultMessage": 'Malicious',
        "id": 'app.report.feature.malicious',
    },
    "suspicious": {
        "defaultMessage": 'Suspicious',
        "id": 'app.report.feature.suspicious',
    },
});
