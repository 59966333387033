import { MidasError } from './Errors';

const HTTP_CODE = {
    "NOT_FOUND": 404,
    "PAYLOAD_TOO_LARGE": 413,
};

export default class MidasRESTAPI {
    constructor(token, getCaptchaProvider, language) {
        this.token = token;
        this.getCaptchaProvider = getCaptchaProvider;
        this.language = language;
    }

    sendRequest(uri, args = {}) {
        const {method, customHeaders, body, ignoreStatus = []} = args;
        const request = {
            "headers": {
                "Midas-Token": this.token,
                "Accept-Language": this.language,
            },
            "method": "GET",
        };

        if (method !== undefined) {
            request.method = method;
        }

        if (customHeaders !== undefined) {
            request.headers = Object.assign(request.headers, customHeaders);
        }

        if (body !== undefined) {
            request.body = body;
        }

        return fetch(`/api/v1${uri}`, request)
            .then(async response => {
                const includes = ignoreStatus.indexOf(response.status) !== -1;

                if (!response.ok && !includes){
                    if (response.status === HTTP_CODE.PAYLOAD_TOO_LARGE) {
                        throw new MidasError("http413");
                    } else {
                        throw new MidasError("unknownError", `${response.status}: ${await response.text()}`);
                    }
                }

                return response;
            });
    }

    getCaptchaResponse() {
        return this.getCaptchaProvider.then(captchaProvider => captchaProvider.getCaptchaChallengeResponse())
            .then(captchaResponse => ({'Midas-Captcha-Response': captchaResponse}));
    }

    getStatus() {
        return this.sendRequest('/status?message=1');
    }

    hashResult(fileHash) {
        return this.sendRequest(`/file/${fileHash}`, {"ignoreStatus": [HTTP_CODE.NOT_FOUND]});
    }

    hashReport(fileHash) {
        return this.sendRequest(`/file/${fileHash}/report`, {"ignoreStatus": [HTTP_CODE.NOT_FOUND]});
    }

    submitFile(fileHash, fileName, fileData) {
        return this.getCaptchaResponse().then(customHeaders => {
            customHeaders["Midas-Filename"] = unescape(encodeURIComponent(fileName));
            customHeaders["Midas-Hash"] = fileHash;

            return this.sendRequest("/file/submit", {
                "method": "PUT",
                "body": fileData,
                customHeaders,
            });
        });
    }

    getTestFileUrl() {
        return this.sendRequest('/testfile/url');
    }

    sendMisclassification(fileHash, message) {
        return this.getCaptchaResponse().then(customHeaders => {
            const body = JSON.stringify({"comment": message});

            customHeaders["Content-Type"] = "application/json";

            return this.sendRequest(`/file/${fileHash}/misclassified`, {
                "method": "POST",
                body,
                customHeaders,
            });
        });
    }
}
