import { defineMessages } from 'react-intl';

export default defineMessages({
    "indicators": {
        "defaultMessage": "Indicators",
        "id": 'app.report.indicators',
    },
    "summary": {
        "defaultMessage": 'Summary',
        "id": 'app.report.summary',
    },
});

