import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
} from 'reactstrap';

import logoStormshield from '../../img/stormshield_logo.png';
import MidasStatus from "./Status";
import TestfileWidget from "./Testfile";

class MidasNavBar extends Component {
    render() {
        return (
            <Navbar expand="md" light>
                <NavbarBrand href="/">
                    <img className="align-bottom" src={logoStormshield} alt="Stormshield"/>
                </NavbarBrand>

                <Nav className="ml-auto" navbar>
                    <NavItem className="pr-3">
                        <TestfileWidget {...this.props} />
                    </NavItem>
                </Nav>

                <MidasStatus className="pr-3" {...this.props} />
            </Navbar>
        );
    }
}

export default MidasNavBar;
