import { defineMessages } from 'react-intl';

export default defineMessages({
    "copied": {
        "defaultMessage": 'Hash copied',
        "id": 'app.report.hash.copied',
    },
    "copy": {
        "defaultMessage": 'Copy the hash',
        "id": 'app.report.hash.copy',
    },
});

