import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {Col, Card, CardImg, CardBody, CardText, CardLink} from 'reactstrap';

class Ad extends Component {
    render(){
        return <Col xs={12} sm={6} md={4}>
            <Card className="h-100">
                <CardImg className="marketing" top src={this.props.img} />
                <CardBody>
                    <CardText>{this.props.text}</CardText>
                    <CardLink href={this.props.link}>{this.props.linkText}</CardLink>
                </CardBody>
            </Card>
        </Col>;
    }
}
export default Ad;
