/* eslint-disable global-require */
const LanguageSupported = {
    "en": {
        "localData": require('./translations/en'),
    },
    "fr": {
        "localData": require('./translations/fr'),
    },
};
/* eslint-enable global-require */

module.exports = LanguageSupported;
