import { Row, Col } from 'reactstrap';
import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {FormattedMessage} from 'react-intl';

import Score from "./Score";
import Section from "./Section";
import FILE_STATUS from "../analysis/Status";
import FileInfo from "./FileInfo";
import Misclassification from "../Misclassification";
import messages from './Report.messages';
import logoBreachFighter from '../../img/stormshield_breach_fighter_logo.svg';

class MidasReport extends Component {
    getHeader() {
        let misclass = "";
        let subTitle = "";

        if (this.props.status === FILE_STATUS.FINISH) {
            if (this.props.fileinfo) {
                subTitle = <Score label={this.props.fileinfo.score_label} />;
            }
            misclass = <Misclassification api={this.props.api} hash={this.props.hash} />;
        } else {
            subTitle = this.props.status;
        }

        return <Row className="align-items-end">
            <Col xs={12} md={{"size": "auto",
                "order": 12}}>
                <img id="logo-bf" src={logoBreachFighter} alt="Breach Fighter"/>
            </Col>
            <Col xs={12} md={{"size": "auto",
                "order": 0}} className="mr-auto">
                <h2>{subTitle}{misclass}</h2>
            </Col>
        </Row>;
    }

    getMainTitle() {
        if (!this.props.fileinfo || !this.props.fileinfo.details) {
            return null;
        }

        return <Row><Col><h1>{this.props.fileinfo.details}</h1></Col></Row>;
    }

    getSummary() {
        if (!this.props.summary) {
            return null;
        }

        return <Row>
            <Col className="summary">
                {this.props.summary}
            </Col>
        </Row>;
    }

    getSection() {
        if (!this.props.report || !this.props.report.length > 0) {
            return null;
        }

        const sections = this.props.report.map(reportItem=>(<Section
            key={reportItem.cat_name}
            catName={reportItem.cat_name}
            features={reportItem.cat_features}
            catDetails={reportItem.cat_details}/>)
        );

        return <span>
            <Row>
                <Col>
                    <h2 className="mb-3 mt-5"><FormattedMessage {...messages.indicators} /></h2>
                </Col>
            </Row>
            {sections}
        </span>;
    }

    getFileInfo() {
        return <FileInfo fileinfo={this.props.fileinfo} />;
    }

    render() {
        return (<span>
            {this.getHeader()}
            {this.getMainTitle()}
            {this.getSummary()}
            {this.getSection()}
            {this.getFileInfo()}
        </span>);
    }
}

export default MidasReport;
