import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import {injectIntl} from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import Routes from "./routes/Routes";
import './App.css';
import NavBar from "./navbar/NavBar";
import RESTAPI from './Api';
import Footer from './Footer';
import Recaptcha from './Captcha';
import NeedUserApproval from './NeedUserApproval';

class App extends Component {
    state = {
        "captchaProvider": null,
    };

    getCaptchaProvider() {
        return new Promise(resolve => {
            this.setState({
                "captchaProvider": <Recaptcha
                    locale={this.props.intl.locale}
                    ref={captchaProviderInstance => {
                        resolve(captchaProviderInstance);
                    }}
                    sitekey={window.conf.reCaptchaSiteKey}
                    size="invisible"
                />
            });
        });
    }

    componentDidMount() {
        this.initApi(window.conf);
    }

    initApi(conf) {
        const api = new RESTAPI(conf.token, this.getCaptchaProvider(), this.props.intl.locale);

        this.setState({api});
    }

    render() {
        return (
            <div className="flex-wrapper">
                <div className="d-flex flex-column wrapper">
                    <NavBar api={this.state.api}/>
                    <div id="main">
                        <BrowserRouter onUpdate={this.fireTracking}>
                            <Routes api={this.state.api}/>
                        </BrowserRouter>
                        {this.state.captchaProvider}
                    </div>
                    <NeedUserApproval/>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default injectIntl(App);
