import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import WrongURL from './WrongURL';
import Home from './Home';
import LegalNotice from '../LegalNotice';
import AnalysisDetailed from "../analysis/Detailed";
import MidasRESTAPI from '../Api';
import messages from './Routes.messages';

export default class Routes extends Component {
    static propTypes = {
        "api": PropTypes.instanceOf(MidasRESTAPI)
    }

    state = {
        "status": "up",
        "volume_status": "normal",
    }

    updateStatus(newState) {
        this.setState({
            "status": newState.result.status,
            "volume_status": newState.result.volume_status,
        });
    }

    getStatus() {
        this.props.api.getStatus()
            .then(result=>result.json())
            .then(result=>this.updateStatus({ result }))
            .catch(()=>this.updateStatus({ "result": {
                "status": "down",
                "volume_status": "monthly_limit_reached",
            } }));
    }

    componentDidMount() {
        if (this.props.api !== undefined){
            this.getStatus();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.api === undefined && this.props.api !== undefined) {
            this.getStatus();
        }
    }

    render(){
        if (this.state.status !== "up" || this.state.volume_status === "monthly_limit_reached") {
            return <FormattedMessage { ...messages.maintenance }/>;
        }

        return <Switch>
            <Route
                exact path="/"
                render={()=>(
                    <Home
                        api={this.props.api}
                    />
                )}
            />
            <Route
                exact path="/:fileHash([0-9a-fA-f]{64})"
                render={({ match })=>(
                    <AnalysisDetailed
                        api={this.props.api}
                        key={match.params.fileHash}
                        fileHash={match.params.fileHash}
                    />
                )}
            />
            <Route path="/legal-notice" component={LegalNotice} />
            <Route path="/" component={WrongURL} />
        </Switch>;
    }
}
