import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import shajs from 'sha.js';

import Score from "../report/Score";
import messages from './Short.messages';
import FILE_STATUS from "./Status";
import AnalysisBase from "./AnalysisBase";
import Marketing from "../marketing/Marketing";

const FILENAME_EXTENSION_SIZE = 10;

export default class AnalysisShort extends AnalysisBase {
    static propTypes = {
        "api": PropTypes.object.isRequired,
        "fileHash": PropTypes.string,
        "handleStatusChange": PropTypes.func,
    };

    submitFile() {
        this.setState({ "status": FILE_STATUS.SUBMITTING });

        return this.props.api.submitFile(this.fileHash, this.state.fileName, this.fileData);
    }

    componentDidMount() {
        this.doAnalyse();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.handleStatusChange !== undefined && this.state.status !== prevState.status) {
            this.props.handleStatusChange(this.state.status);
        }
        if (this.props.file !== prevProps.file || this.props.fileHash !== prevProps.fileHash) {
            this.doAnalyse();
        }
    }

    doAnalyse() {
        this.clearTimeout();
        this.setState({
            "status": FILE_STATUS.LOCAL_PROCESSING,
            "fileName": undefined,
            "fileHash": undefined,
            "fileinfo": undefined,
            "report": undefined,
            "summary": undefined,
        });
        this.fileHash = undefined;
        this.fileData = undefined;
        this.getOrComputeFileHash()
            .then(fileHash => {
                this.setState({fileHash});
                this.fileHash = fileHash;

                return this.sendHashRequest(fileHash);
            })
            .then(hashResult => {
                if (hashResult.submit_file && this.fileData !== undefined) {
                    return this.submitFile();
                }

                return undefined;
            })
            .then(() => {
                this.setState({ "status": FILE_STATUS.ANALYSIS_IN_PROGRESS });
                this.setFileReportInState(this.fileHash);
            })
            .then(() => this.setState({'finished': true}))
            .catch(error => this.handleError(error));
    }

    getFileData() {
        return new Promise(resolve => {
            const reader = new FileReader();

            reader.onload = event => (resolve(event.target.result));
            reader.readAsArrayBuffer(this.props.file);
        });
    }

    getOrComputeFileHash() {
        return new Promise((resolve, reject) => {
            if (this.props.fileHash) {
                resolve(this.props.fileHash);
            } else if (this.props.file) {
                this.setState({ "fileName": this.props.file.name });
                // setting a timeout would avoids blocking the main thread during hash computation.
                // However, it's not working properly with unit tests
                // Use a WebWorker instead ?
                this.getFileData()
                    .then(data => {
                        this.fileData = data;

                        return this.computeFileHash(data);
                    })
                    .then(fileHash => resolve(fileHash))
                    .catch(err => reject(err));
            } else {
                reject(new Error("No data for this file"));
            }
        });
    }

    computeFileHash(data) {
        return shajs('sha256').update(new Int8Array(data))
            .digest('hex');
    }

    getDetails() {
        if (this.state.status === FILE_STATUS.FINISH){
            return <a className="text-dark" href={`/${this.state.fileHash}`}>
                {this.state.fileinfo.details}
            </a>;
        }

        return null;
    }

    getStatus() {
        if (this.state.status === FILE_STATUS.FILE_UNKNOWN) {
            return <Score label="unknown" />;
        }

        if (this.state.status === FILE_STATUS.ERROR) {
            return <Score label="error" />;
        }

        if (this.state.status === FILE_STATUS.FINISH) {
            return (
                <a className="text-dark" href={`/${this.state.fileHash}`}>
                    <Score label={this.state.fileinfo.score_label} />
                </a>
            );
        }

        return (
            <span>
                <FontAwesome spin className="text-info mr-2" name="refresh" />
                <FormattedMessage {...messages.wait} />
            </span>
        );
    }

    getTitle() {
        return (
            <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
                <div className="pr-lg-2">
                    {this.getStatus()}
                </div>
                <div className="pr-lg-2 details">
                    {this.getDetails()}
                </div>
                {this.getFileName()}
            </div>
        );
    }

    getFileName() {
        if (!this.state.fileName) {
            return null;
        }

        return (
            <div className="d-flex justify-content-start text-truncate">
                <div className="text-truncate">{this.state.fileName.slice(0, -FILENAME_EXTENSION_SIZE)}</div>
                <div>{this.state.fileName.slice(-FILENAME_EXTENSION_SIZE)}</div>
            </div>
        );
    }

    getContent() {
        let marketing = null;
        let data = null;

        if (this.state.status === FILE_STATUS.FILE_UNKNOWN ||
            this.state.status === FILE_STATUS.ERROR ||
            this.state.status === FILE_STATUS.FINISH){
            marketing = <Marketing />;
        }

        if (this.state.status === FILE_STATUS.FILE_UNKNOWN) {
            data = <FormattedMessage {...messages.unknown} />;
        } else if (this.state.status === FILE_STATUS.ERROR) {
            data = this.state.fileinfo.details;
        } else if (this.state.status === FILE_STATUS.FINISH) {
            data = <span>
                {this.state.summary} <a href={`/${this.state.fileHash}`}>
                    <FormattedMessage {...messages.moreInfo} />
                </a>
            </span>;
        } else if (this.state.status === FILE_STATUS.LOCAL_PROCESSING) {
            data = <FormattedMessage {...messages.local_processing} />;
        } else if (this.state.status === FILE_STATUS.SUBMITTING) {
            data = <FormattedMessage {...messages.submitting} />;
        } else {
            data = <FormattedMessage {...messages.duration} />;
        }

        return (
            <Row>
                <Col>
                    {data}
                </Col>
                {marketing}
            </Row>
        );
    }

    render() {
        return (
            <Container>
                <h2 className="mb-3 mt-5">
                    {this.getTitle()}
                </h2>
                {this.getContent()}
            </Container>
        );
    }
}
